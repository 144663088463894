@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA1B;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-arrow-bottom:before {
		content: '\ea01';
	}
	
	.be-icon-arrow-left:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-right:before {
		content: '\ea03';
	}
	
	.be-icon-arrow-top:before {
		content: '\ea04';
	}
	
	.be-icon-checked:before {
		content: '\ea05';
	}
	
	.be-icon-close:before {
		content: '\ea06';
	}
	
	.be-icon-dot-h:before {
		content: '\ea07';
	}
	
	.be-icon-dot-v:before {
		content: '\ea08';
	}
	
	.be-icon-download:before {
		content: '\ea09';
	}
	
	.be-icon-email:before {
		content: '\ea0a';
	}
	
	.be-icon-fb:before {
		content: '\ea0b';
	}
	
	.be-icon-global:before {
		content: '\ea0c';
	}
	
	.be-icon-global2:before {
		content: '\ea0d';
	}
	
	.be-icon-link:before {
		content: '\ea0e';
	}
	
	.be-icon-linkedin:before {
		content: '\ea0f';
	}
	
	.be-icon-map-marker:before {
		content: '\ea10';
	}
	
	.be-icon-number:before {
		content: '\ea11';
	}
	
	.be-icon-pdf:before {
		content: '\ea12';
	}
	
	.be-icon-reload:before {
		content: '\ea13';
	}
	
	.be-icon-search:before {
		content: '\ea14';
	}
	
	.be-icon-share:before {
		content: '\ea15';
	}
	
	.be-icon-solution-asic:before {
		content: '\ea16';
	}
	
	.be-icon-solution-ip:before {
		content: '\ea17';
	}
	
	.be-icon-solution-pd:before {
		content: '\ea18';
	}
	
	.be-icon-video:before {
		content: '\ea19';
	}
	
	.be-icon-warning:before {
		content: '\ea1a';
	}
	
	.be-icon-wechat:before {
		content: '\ea1b';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-SVG檔案名稱;
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-arrow-bottom: '\ea01';
@mixin font-icon-arrow-bottom($extendStyle: true) {
	content: '\ea01';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-left: '\ea02';
@mixin font-icon-arrow-left($extendStyle: true) {
	content: '\ea02';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-right: '\ea03';
@mixin font-icon-arrow-right($extendStyle: true) {
	content: '\ea03';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-top: '\ea04';
@mixin font-icon-arrow-top($extendStyle: true) {
	content: '\ea04';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-checked: '\ea05';
@mixin font-icon-checked($extendStyle: true) {
	content: '\ea05';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-close: '\ea06';
@mixin font-icon-close($extendStyle: true) {
	content: '\ea06';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-dot-h: '\ea07';
@mixin font-icon-dot-h($extendStyle: true) {
	content: '\ea07';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-dot-v: '\ea08';
@mixin font-icon-dot-v($extendStyle: true) {
	content: '\ea08';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-download: '\ea09';
@mixin font-icon-download($extendStyle: true) {
	content: '\ea09';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-email: '\ea0a';
@mixin font-icon-email($extendStyle: true) {
	content: '\ea0a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-fb: '\ea0b';
@mixin font-icon-fb($extendStyle: true) {
	content: '\ea0b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-global: '\ea0c';
@mixin font-icon-global($extendStyle: true) {
	content: '\ea0c';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-global2: '\ea0d';
@mixin font-icon-global2($extendStyle: true) {
	content: '\ea0d';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-link: '\ea0e';
@mixin font-icon-link($extendStyle: true) {
	content: '\ea0e';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-linkedin: '\ea0f';
@mixin font-icon-linkedin($extendStyle: true) {
	content: '\ea0f';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-map-marker: '\ea10';
@mixin font-icon-map-marker($extendStyle: true) {
	content: '\ea10';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-number: '\ea11';
@mixin font-icon-number($extendStyle: true) {
	content: '\ea11';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-pdf: '\ea12';
@mixin font-icon-pdf($extendStyle: true) {
	content: '\ea12';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-reload: '\ea13';
@mixin font-icon-reload($extendStyle: true) {
	content: '\ea13';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-search: '\ea14';
@mixin font-icon-search($extendStyle: true) {
	content: '\ea14';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-share: '\ea15';
@mixin font-icon-share($extendStyle: true) {
	content: '\ea15';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-solution-asic: '\ea16';
@mixin font-icon-solution-asic($extendStyle: true) {
	content: '\ea16';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-solution-ip: '\ea17';
@mixin font-icon-solution-ip($extendStyle: true) {
	content: '\ea17';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-solution-pd: '\ea18';
@mixin font-icon-solution-pd($extendStyle: true) {
	content: '\ea18';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-video: '\ea19';
@mixin font-icon-video($extendStyle: true) {
	content: '\ea19';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-warning: '\ea1a';
@mixin font-icon-warning($extendStyle: true) {
	content: '\ea1a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-wechat: '\ea1b';
@mixin font-icon-wechat($extendStyle: true) {
	content: '\ea1b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}
