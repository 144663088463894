// 說明 (完整說明見) src/sass/style.sass
// 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------

/* [START] Loader */
.loader {
	$root: &;

	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background: $white;
	cursor: wait;

	/* stylelint-disable */
	// Ref: https://codepen.io/hexagoncircle/pen/MKaVzM
	// 正六角形設定
	//
	//     $hex-border-lr
	//      ↓        ↓
	// |---------|---------|
	//                       __________
	//         ╱|╲              |
	//      ╱  1x   2x     $hex-border-tb
	//    ╱     |     ╲         |
	//  ╱       |┐   30°╲      |
	// |╲⁻⁻√3x⁻⁻|⁻⁻√3x⁻⁻╱|   ⁻⁻⁻|⁻⁻⁻
	// |  ╲     |     ╱  |      |
	// |     ╲  |  ╱    1x      |
	// |_______╲|╱_______|  $hex-height
	// |       ╱|╲       |      |
	// |     ╱  |  ╲    1x      |
	// |   ╱    |    ╲   |      |
	// |╱_______|______╲ |   ___|___
	//  ╲       |       ╱       |
	//    ╲     |     ╱         |
	//      ╲   1x  2x     $hex-border-tb
	//         ╲|╱         _____|_____
	//
	// |--- $hex-width ----|
	//
	$hex-width: 100px;
	$hex-height: round($hex-width / sqrt(3) * 100) / 100; // 僅中間的高 = 寬度 / 根號3
	$hex-border-tb: $hex-height / 2; // 上下三角形的高 = 中間的高 / 2
	$hex-border-lr: $hex-width / 2;
	$hex-color: $white; // 六角形的實色
	$loader-bg: $black; // 左右門的顏色
	/* stylelint-enable */

	// 六角形外層
	&__hex-wrapper {
		position: absolute;
		z-index: 5;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		// 放置 Logo G
		&::before {
			content: '';
			position: absolute;
			z-index: 3;
			top: $hex-border-tb * -1;
			padding-top: percentage(($hex-height * 2) / $hex-width);
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 60%;
			background-image: url($logo-g-gray);
			animation: text-flicker 1s infinite;
		}
	}

	// 六角形
	&__hex {
		--hex-primary-color: #{hex-to-rgb($hex-color)};
		--hex-color: var(--hex-primary-color);

		position: relative;
		z-index: 2;
		margin: auto;
		width: $hex-width;
		height: $hex-height;

		// ※因為用拼組的方式形成六角形在透明時會出現Bug，改用clip
		// 六角形 - 白色背景
		&::before {
			// clip-path 點
			//      1
			//   /     \
			//  /       \
			// 6         2
			// |         |
			// |         |
			// 5         3
			//  \       /
			//   \     /
			//      4
			$square-point-1: 50% 0;
			$square-point-2: 100% 25%;
			$square-point-3: 100% 75%;
			$square-point-4: 50% 100%;
			$square-point-5: 0 75%;
			$square-point-6: 0 25%;

			content: '';
			position: absolute;
			top: $hex-border-tb * -1;
			left: 0;
			width: 100%;
			padding-top: percentage(($hex-height * 2) / $hex-width);
			background-color: var(--hex-color);
			clip-path: polygon(
				#{$square-point-1},
				#{$square-point-2},
				#{$square-point-3},
				#{$square-point-4},
				#{$square-point-5},
				#{$square-point-6}
			);
		}

		&__border {
			opacity: 0;

			&,
			&::before,
			&::after {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-right: 1px solid $primary-color;
				border-left: 1px solid $primary-color;
			}

			&::before {
				transform: rotate(#{(360deg / 3)});
			}

			&::after {
				transform: rotate(#{(360deg / 3 * 2)});
			}
		}
	}

	// 跑完頁面
	&.loaded {
		pointer-events: none;

		#{$root} {
			&__hex-wrapper::before,
			&__hex {
				transform: scale(0.8);
				transition: transform 0.6s;
			}

			&__hex-wrapper {
				opacity: 0;
				transition: opacity 0.3s 0.6s;

				&::before {
					background-image: url($logo-g-primary);
					animation: none;
				}
			}

			&__hex {
				--hex-color: rgba(var(--hex-primary-color), 0.8);

				&__border {
					opacity: 1;
					transition: opacity 0.3s;
				}
			}
		}
	}
}

@keyframes text-flicker {
	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}
}

/* [END] Loader */

// -------------------------------------------------------

/* [START] Header */
:root {
	--hd-height: #{rem-calc(44)}; // Header 高

	@include breakpoint(large) {
		--hd-height: #{rem-calc(80)};

		&.is-collapse {
			&.is-scroll-down {
				--hd-height: #{rem-calc(60)};
			}
		}
	}
}

.hd {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;

	@media (min-width: $hd-menu-bp) {
		transition: transform 0.3s;

		&.is-collapse {
			&.is-scroll-down {
				transform: translateY(-40px);
			}
		}
	}
}

// Logo
.brand-logo {
	position: relative;
	display: block;
	overflow: hidden;
	width: 72px;
	font-size: rem-calc(14);

	&::before {
		content: '';
		display: block;
		padding-top: percentage(27 / 109); // 沒有 Slogan
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}

	@include breakpoint(large) {
		width: 109px;

		&::before {
			padding-top: percentage(41 / 109); // 有 Slogan
		}
	}

	@include breakpoint-hover {
		transition: opacity 0.5s;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: -20px;
			width: 20px;
			height: 100%;
			background: linear-gradient(to right, rgba($white, 0) 0%, $white 50%, rgba($white, 0) 100%);
			transform: skewX(-30deg);
			transform-origin: right top;
		}

		&:hover,
		&:focus {
			opacity: 0.5;

			&::after {
				transform: skewX(-30deg) translateX(#{percentage((109 + 20 * 2) / 20)});
				transition: transform 0.5s;
			}
		}
	}
}

// Hamburger Menu
.hd-ham {
	$root: &;

	&__svg {
		display: block;
		transition: transform 0.4s;
	}

	&__line {
		fill: none;
		stroke: $primary-color;
		stroke-width: 3;
		stroke-linecap: round;
		transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;

		&.top {
			stroke-dasharray: 40 82;
		}

		&.middle {
			stroke-dasharray: 40 111;
			stroke-dashoffset: 10;
		}

		&.bottom {
			stroke-dasharray: 40 161;
		}
	}

	// Active (Open) Style
	&.is-open {
		#{$root} {
			&__svg {
				transform: rotate(45deg);
			}

			&__line {
				&.top {
					stroke-dasharray: 17 82;
					stroke-dashoffset: -62;
				}

				&.middle {
					stroke-dashoffset: 23px;
					stroke-dashoffset: 0;
				}

				&.bottom {
					stroke-dashoffset: -83px;
				}
			}
		}
	}

	// PC版選單
	@include breakpoint(large) {
		padding-right: 1rem;

		&__svg {
			width: 54px;
		}
	}
	@media (min-width: $hd-menu-bp) {
		display: none;
	}
}
// END. .hd-ham

%menu-bg {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $reveal-overlay-background;
	transition: transform 1s;
}

/* PC - Search & Language */
.hd-top {
	display: none !important;
	justify-content: flex-end;
	padding: rem-calc(0 100);
	min-height: rem-calc(40);
	color: $white;
	background: $black;

	@media (min-width: $hd-menu-bp) {
		display: flex !important;
	}

	&__button {
		padding: rem-calc(10 20);
		font-size: rem-calc(15);
		text-transform: uppercase;
		color: $white;
		transform: skew(-30deg);

		&__inside {
			display: block;
			transform: skew(30deg);
		}

		.be-icon {
			margin-right: rem-calc(4);
		}

		@include breakpoint-hover {
			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $primary-color;
				transform: scaleX(0);
				transform-origin: right center;
				transition: 0.3s transform;
			}

			&__inside {
				transform: skew(30deg) translate3d(0, 0, 0);
			}

			&:hover,
			&:focus {
				&::before {
					transform: scaleX(1);
					transform-origin: left center;
					will-change: transform;
				}
			}
		}
	}
}

.hd-main {
	display: flex;
	padding-left: rem-calc(15);
	box-shadow: 0 2px 7px rgba($black, 0.15);
	justify-content: space-between;
	align-items: center;

	// 手機版選單
	@media (max-width: $hd-menu-bp)  {
		display: flex;
		align-items: center;
		background: $white;
	}
	// END. 手機版選單

	// PC版選單
	@include breakpoint(large) {
		--hd-height: 80px;
		min-height: rem-calc(80);
	}

	@media (min-width: $hd-menu-bp) {
		--hd-height: 120px;
		position: relative;
		align-items: center;
		padding-left: rem-calc(70);
		background: $white;
	}

	@include breakpoint(xlarge) {
		padding-left: rem-calc(100);
	}
	// END. PC版選單
}
// END. .hd-main

$hd-menu-border: 1px solid #e7e7e7;
$hd-menu-m-padding-x: rem-calc(40);
.hd-menu {
	$root: &;

	ul {
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}

	// 箭頭(手機板的箭頭)
	// 因為 @include font-icon 不能寫於 breakpoint 中，所以要寫這
	@at-root %hd-menu-arrow {
		$el: &;

		&::before {
			@include font-icon-arrow-bottom;

			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			margin-top: rem-calc(-8);
			font-size: rem-calc(16);
			color: $body-font-color;
			transition: transform 0.3s;
		}

		// 手機版選單
		@media (max-width: $hd-menu-bp) {
			padding-right: rem-calc(10);

			@at-root #{$root} {
				&__list {
					.is-open {
						> * {
							&::before {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}

		// PC版選單
		@media (min-width: $hd-menu-bp) {
			&::before {
				display: none;
				opacity: 0;
			}
		}
	}

	&__item {
		&.has-sec {
			> #{$root}__link {
				@extend %hd-menu-arrow;
			}
		}
	}

	// 連結基本樣式
	@at-root %menu-link {
		display: block;
	}

	&__link {
		@extend %menu-link;
	}

	&__sec {
		&__group {
			&.has-sec {
				> #{$root}__sec__title {
					@extend %hd-menu-arrow;
				}
			}
		}

		&__title {
			@extend %menu-link;
		}

		&__link {
			@extend %menu-link;
		}
	}

	&__bg {
		@extend %menu-bg;
	}

	// 手機版選單
	@media (max-width: $hd-menu-bp) {
		position: fixed;
		z-index: 1000;
		top: var(--hd-height);
		right: 0;
		width: 100vw;
		height: calc(100vh - var(--hd-height));
		border-top: $hd-menu-border;
		transform: translateX(100%);
		transition: transform 0.5s;

		&.is-open {
			transform: translateX(0);

			#{$root} {
				&__cont {
					transform: translateX(0);
				}

				&__bg {
					transform: translateX(0);
					transition-duration: 0.5s;
				}
			}
		}

		&__cont {
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			height: 100%;
			background: $white;
			transform: translateX(100%);
			transition: transform (0.5s + 0.3s);
		}

		&__bg {
			width: 100%;
			height: 100%;
			transform: translate(100%);
		}

		// 連結基本樣式
		@at-root %menu-link-m {
			--padding-y: #{rem-calc(9)};

			position: relative;
			margin-bottom: 0;
			padding: var(--padding-y) 0;
			font-size: rem-calc(14);
			line-height: 1.6;
			color: $primary-color;
		}

		// 主選單區 - 最外層<ul>
		&__list {
			margin: 0;
			padding-right: $hd-menu-m-padding-x;
			padding-left: $hd-menu-m-padding-x;
			border-bottom: $hd-menu-border;
		}

		// 主選單區 - 第一層<li>
		&__item {
			&:not(:last-child) {
				border-bottom: $hd-menu-border;
			}

			// 第二層 - 選單收合
			@include toggle-menu-item('.is-open', '.hd-menu__sec__title');

			// 開啟的狀態
			&.is-open {
				&.has-sec > #{$root} {
					&__link {
						color: $primary-color;

						&::before {
							color: $primary-color;
						}
					}
				}

				#{$root} {
					&__sec {
						margin-top: rem-calc(-15);
						margin-bottom: rem-calc(15);
					}
				}
			}

			> #{$root}__link {
				--padding-y: #{rem-calc(27)};

				color: #565656;
			}

			// Header 第一層 Active
			&.is-active {
				#{$root} {
					&__link {
						padding-left: rem-calc(3 + 10);
						color: $primary-color;

						&::after {
							content: '';
							position: absolute;
							top: rem-calc(10);
							left: 0;
							width: rem-calc(3);
							height: calc(100% - #{rem-calc(10 * 2)});
							background: $primary-color;
						}
					}
				}
			}
		}

		// 第一層選項
		&__link {
			@extend %menu-link-m;

			font-size: rem-calc(16);
			font-weight: bold;
			text-transform: uppercase;
		}

		// 第二層選單
		&__sec {
			padding-left: rem-calc(20);

			&__title {
				@extend %menu-link-m;

				&::before {
					display: none !important; // 2021/05/11 確認手機板無子選單
				}

				font-weight: normal;
			}

			// 第三層 - 選單收合
			&__group {
				@include toggle-menu-item('.is-open', '.hd-menu__sec__link');

				// 開啟的狀態
				&.is-open {
					&.has-sec > #{$root} {
						&__sec__title {
							&::before {
								color: $primary-color;
							}
						}
					}
				}
			}

			// 第三層
			&__list {
				display: none; // 2021/05/11 確認手機板無子選單
				padding-left: rem-calc(20);
			}

			&__link {
				@extend %menu-link-m;

				color: rgba($body-font-color, 0.7);
			}
		}

		// 主選單下方區域
		&__list-bottom {
			padding: rem-calc(30) $hd-menu-m-padding-x;
		}
	}

	@media (max-width: $hd-menu-bp) {
		&__cont {
			margin-left: auto;
			max-width: 400px;
		}
	}
	// END. // 手機版選單

	// PC版選單
	@media (min-width: $hd-menu-bp) {
		justify-content: center;
		flex: 1;
		margin-left: rem-calc(10);

		&__cont {
			display: flex;
			justify-content: flex-end;
			margin-right: 25%;
		}

		&__bg {
			display: none;
		}

		// 主選單區 - 最外層<ul>
		&__list {
			display: flex;
		}

		// 第一層
		&__item {
			&:first-child {
				#{$root} {
					&__link {
						padding-left: calc(var(--padding-x) - #{rem-calc(3)});
					}
				}
			}

			// 黑底背景
			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				display: none;
				width: 100%;
				height: 100vh;
				background: $reveal-overlay-background;
				pointer-events: none;
			}

			@at-root %header-first-active {
				#{$root} {
					&__link {
						color: $primary-color;

						&::after {
							transform: scaleX(1);
							transition: transform 0.3s;
						}
					}
				}
			}

			// Open
			&:hover,
			&:focus-within {
				@extend %header-first-active;

				&::before {
					display: block;
				}

				#{$root} {
					&__sec {
						display: block;
					}
				}
			}

			// Header 第一層 Active
			&.is-active {
				@extend %header-first-active;
			}
		}

		// 第一層 - 選項
		&__link {
			--padding-x: #{rem-calc(15)};
			--padding-y: #{rem-calc(26)};

			position: relative;
			padding: var(--padding-y) var(--padding-x);
			color: $black;

			// 手機板箭頭
			&::before {
				display: none;
			}

			// Active Bar
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: var(--padding-x);
				width: calc(100% - var(--padding-x) * 2);
				height: 4px;
				background: $primary-color;
				transform: scaleX(0);
			}
		}

		// 第二層選單
		&__sec {
			position: absolute;
			z-index: 5;
			top: rem-calc(80);
			left: 0;
			display: none;
			width: 100%;
			background: $white;
			border-top: 1px solid $primary-color;

			&__cont {
				--padding-x: #{rem-calc(70)};

				display: flex;
				margin: 0 auto !important;
				padding: rem-calc(50) var(--padding-x);
				max-width: #{$global-width - rem-calc(25) * 2}; // 25: 選項的左右 padding
			}

			&__group {
				margin-right: rem-calc(100);
				max-width: 25%;
			}

			&__title {
				margin-bottom: rem-calc(13);
			}

			&__link {
				padding-top: rem-calc(5);
				padding-bottom: rem-calc(5);
				font-size: rem-calc(16);
				color: $body-font-color;
			}
		}
	}

	@media (min-width: $hd-menu-bp) {
		// 第一層
		&__link {
			--padding-x: #{rem-calc(25)};
		}

		// 第二層選單
		&__sec {
			&__cont {
				--padding-x: #{rem-calc(100)};
			}
		}
	}
	// END. PC版選單
}
// END .hd-menu

// 主選單下方 - 特殊設計物件(GUC Online + Contact(Mobile))
.hd-sp-button {
	$skew: 30deg; // 傾斜的角度

	--skew: #{$skew};

	&__link {
		display: block;
		padding: rem-calc(12 10);
		font-weight: bold;
		text-align: center;
		color: $primary-color;
		border: 1px solid $primary-color;
		transform: skewX(calc(var(--skew) * -1));

		.text {
			display: block;
			transform: skewX(var(--skew));
		}
	}

	// 手機版選單
	@media (max-width: $hd-menu-bp) {
		--margin-x: #{rem-calc(10)};
		--tri-width: #{sin($skew) * (51px / cos($skew))};

		display: flex;
		overflow: hidden;
		margin-bottom: rem-calc(3);

		&__item {
			flex: 1;
			// 左按鈕 - GUC Online (Gradient Solid)
			&:first-child {
				margin-right: var(--margin-x);
				margin-left: calc(var(--tri-width) / -2);

				.text {
					margin-left: calc(var(--tri-width) / 3);
				}
			}

			// 右按鈕 - Contact Us (Border)
			&:last-child {
				position: relative;
				margin-right: calc(var(--tri-width) / -2);
				margin-left: var(--margin-x);

				&::before {
					content: '';
					position: absolute;
					top: 0;
					right: calc(var(--margin-x) + 4.6px);
					width: 1px;
					height: 100%;
					background: $primary-color;
				}

				.text {
					margin-right: calc(var(--tri-width) / 3);
				}
			}
		}
	}

	@include breakpoint($xs-down) {
		font-size: rem-calc(14);
	}
	// END. 手機版選單

	// PC版選單
	@media (min-width: $hd-menu-bp) {
		.contact {
			display: none;
		}
	}
	// END. PC版選單
}

// GUC Online 獨立出來
.guc-online {
	// --skew: 30deg;
	color: $white;
	background-image: $primary-gradient;
	border: 0 none;

	// 防止Global設定的Hover顯示
	&:hover {
		color: $white;
	}

	// 手機版選單
	@media (max-width: $hd-menu-bp) {
		.text-arrow {
			&::after {
				display: none;
			}
		}
	}

	// PC版選單
	@media (min-width: $hd-menu-bp){
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20%;
		max-width: 320px;
		height: 100%;
		white-space: nowrap;
		transform-origin: right bottom;

		.text-arrow {
			font-weight: normal;
			text-transform: none;
		}
	}

	// 1440+
	@include breakpoint(xxlarge) {
		--padding-x: #{rem-calc(114)};

		padding-right: var(--padding-x);
		padding-left: var(--padding-x);
		width: auto;
		max-width: none;
	}

	@include breakpoint-hover {
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 1px;
			left: 1px;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			background: $white;
			transform: scaleX(0);
			transform-origin: right center;
			transition: 0.5s transform;
		}

		&:hover,
		&:focus {
			color: $primary-color;

			.text-arrow {
				--move-x: 20px;
			}

			&::before {
				transform: scaleX(1);
				transform-origin: left center;
			}
		}
	}
	// END. PC版選單
}
// END. GUC Online

// 語言選擇
.hd-lang {
	&__button-open {
		height: 100%;

		.be-icon-arrow-bottom {
			margin-right: 0;
			margin-left: rem-calc(10);
			font-size: rem-calc(12);
		}
	}

	&__link {
		display: block;
		color: $body-font-color;

		&.is-active {
			font-weight: bold;
			color: $primary-color;
		}
	}

	&__bg {
		@extend %menu-bg;

		@media (max-width: $hd-menu-bp) {
			display: none;
		}
	}

	&.pc {
		margin-right: rem-calc(-24); // 為了讓右側視覺 Margin 是 100px
	}

	&.mobile {
		.be-icon {
			position: absolute;
			top: 23px;
			left: 0;
			font-size: rem-calc(20);
			color: #d5d5d5;
			transform: translateY(-50%);

			@include breakpoint(large) {
				top: 34px;
				left: 5px;
			}
		}
	}

	// 手機版選單
	@media (max-width: $hd-menu-bp) {
		--margin-right: #{rem-calc(8)};

		&.mobile {
			position: relative;
			margin-bottom: rem-calc(13);
			padding-left: rem-calc(40);
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			margin-right: calc(var(--margin-right) * -1);
		}

		&__item {
			margin-right: var(--margin-right);
			width: calc(33.33% - var(--margin-right));
		}

		&__link {
			padding: rem-calc(14) var(--margin-right);
			font-size: rem-calc(13);

			&.is-active {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint($xs-down) {
		--margin-right: #{rem-calc(4)};

		&__link {
			font-size: rem-calc(12);
		}
	}
	// END. 手機版選單

	// PC版選單
	@media (min-width: $hd-menu-bp) {
		&.mobile {
			position: fixed;
			z-index: 5;
			top: 40px;
			right: 0%;
			display: none;
			width: 100%;

			> .be-icon {
				display: none;
			}

			&.is-open {
				display: block;
			}
		}

		&__list {
			--move-x: 95.5px;

			position: absolute;
			right: calc(100px + var(--move-x));
			// overflow: hidden;
			min-width: rem-calc(250);
			// max-height: 0;
			background: $white;
			transform: translateX(50%);
		}

		&__item {
			text-align: center;

			&:first-child {
				margin-top: rem-calc(29);
			}

			&:last-child {
				margin-bottom: rem-calc(29);
			}
		}

		&__link {
			padding: rem-calc(9 29);
		}
	}
	// END. PC版選單
}
// END. hd-lang

.hd-search {
	$root: &;

	border-bottom: $hd-menu-border;

	&__input-group {
		position: relative;
	}

	&__button-submit {
		position: absolute;
		top: 0;
		left: $hd-menu-m-padding-x;
		height: 100%;
		color: #c6c6c6;
		cursor: pointer;
	}

	&__input {
		--padding-y: #{rem-calc(12)};

		margin-bottom: 0;
		padding: var(--padding-y) $hd-menu-m-padding-x + rem-calc(27);
		padding-left: $hd-menu-m-padding-x + rem-calc(42);
		box-shadow: none;

		&,
		&:focus {
			border: 0 none;
		}
	}

	&__clean {
		position: absolute;
		top: 50%;
		right: 40px;
		cursor: pointer;
		color: #999;
		transform: translateY(-50%);

		@media (min-width: $hd-menu-bp) {
			right: 180px;
		}
	}

	&__bg {
		@extend %menu-bg;

		@media (max-width: $hd-menu-bp) {
			display: none;
		}
	}

	// PC版選單
	@media (min-width: $hd-menu-bp) {
		position: fixed;
		z-index: 5;
		top: 40px;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;

		&.is-open {
			display: block;
		}

		&__bg {
			display: block;
		}

		&__button-submit {
			--size: #{rem-calc(45)};

			top: 50%;
			right: rem-calc(110);
			left: auto;
			width: var(--size);
			height: var(--size);
			color: $primary-color;
			border: 1px solid $primary-color;
			border-radius: 100%;
			transform: translateY(-50%);
		}

		&__input {
			--padding-x: #{$hd-menu-m-padding-x + rem-calc(180)};

			padding-right: var(--padding-x); // 搜尋字要置中，所以左右padding要一樣
			padding-left: var(--padding-x); // 搜尋字要置中，所以左右padding要一樣
			height: rem-calc(130);
			font-size: rem-calc(26);
			text-align: center;
			background: $white;
		}
	}

	@include breakpoint-hover {
		&__button-submit {
			transition: box-shadow 0.3s;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid $primary-color;
				border-radius: 100%;
				transform: scale(0);
				transition: transform 0.3s;
			}

			&:hover,
			&:focus {
				box-shadow: 0 0 30px rgba($primary-color, 0.43);

				&::before {
					transform: scale(1);
				}
			}
		}
	}
	// END. PC版選單
}
// END. .hd-search

/* [END] Header */

// --------------------------------------------------------

/* [START] Footer */
.ft {
	position: relative;

	@at-root %bg-mask {
		content: '';
		position: absolute;
		z-index: -2;
		left: 0;
		width: 100%;
	}

	// FOOTER 中的遮罩高
	@at-root :root {
		--ft-mask-height: #{rem-calc(100)};
		--ft-mask-top-height: 500px; // 位於Footer以上的範圍

		@include breakpoint(medium) {
			--ft-mask-height: #{rem-calc(150)};
		}

		@include breakpoint(large) {
			--ft-mask-height: #{rem-calc(250)};
		}
	}

	&::before {
		// |                       |
		// ├- - - - - - - - - - - -┤  ┬ rgba($white, 0)
		// |                       |  |
		// |     Content Mask      |  | --ft-mask-top-height: 500px (large+)
		// |                       |  |
		// ├-----------------------┤  ┼ rgba($white, 0.3)
		// |    Footer Mask Area   |  | --ft-mask-height: 250px (large+)
		// ├- - - - - - - - - - - -┤  ┴ rgba($white, 1)
		// |   Footer 純白背景區    |

		@extend %bg-mask;

		top: calc(var(--ft-mask-top-height) * -1);
		height: calc(var(--ft-mask-height) + var(--ft-mask-top-height)); // 寫在上面
		background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);
	}

	// 遮罩以外的白色背景區域
	&::after {
		@extend %bg-mask;

		bottom: 0;
		height: calc(100% - var(--ft-mask-height));
		background: $white;
	}

	@include breakpoint(small only) {
		font-size: rem-calc(14);
	}
}

// Footer - 固定在右下的物件
.ft-fixed-box {
	position: absolute;
	z-index: 100;
	top: (-180px - 5);
	right: 15px;

	@include breakpoint(medium) {
		right: 10px;
	}

	@include breakpoint(xlarge) {
		right: 25px;
	}

	@include breakpoint(1920) {
		right: 115px;
	}

	&__item {
		margin-bottom: rem-calc(20);
	
		@include breakpoint(large) {
			&:last-child {
				margin-bottom: rem-calc(100);
			}
		}
	}

	@include breakpoint(medium) {
		top: 136px;
	}

	&.is-fixed {
		position: fixed;
		top: auto;
		bottom: 0;
	}
}

// ===== Footer - Main =====
.ft-main {
	@include breakpoint(medium) {
		padding: rem-calc(50 0);
		border-bottom: 1px solid #9c9c9c;
	}
}

%ft-title {
	display: block;
	margin-bottom: rem-calc(6);
	font-size: rem-calc(16);
	font-weight: bold;
	text-transform: uppercase;
	color: $primary;

	@include breakpoint(medium) {
		margin-bottom: rem-calc(16);
		font-size: rem-calc(18);
	}
}

// Footer - Sitemap
.ft-sitemap {
	$root: &;

	margin-bottom: 0;

	&__group {
		--border: 1px solid #e7e7e7;
	}

	&__title {
		@extend %ft-title;

		line-height: 1.2;

		// 箭頭(手機板的箭頭)
		// 因為 @include font-icon 不能寫於 breakpoint 中，所以要寫這
		&::before {
			@include font-icon-arrow-bottom;

			display: none; // Hide For Normal
		}

		// 物件
		@include breakpoint(medium) {
			cursor: default;
			pointer-events: none;

			&:hover {
				color: $primary-color;
			}
		}
	}

	&__link {
		display: block;
		padding: rem-calc(12);
		padding-left: rem-calc(15);
		font-size: rem-calc(14);
		line-height: 1.2;
		color: $primary-color;

		@include breakpoint-hover {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	// Small 下拉展開樣式
	@include breakpoint(small only) {
		&__group {
			border-bottom: var(--border);

			@include toggle-menu-item('.is-open', '.ft-sitemap__link');

			// 未展開
			&:not(.is-open) {
				#{$root} {
					&__sec {
						transition: margin 0.3s;
					}
				}
			}

			// 展開
			&.is-open {
				#{$root} {
					&__title {
						color: $primary-color;

						&::before {
							margin-top: rem-calc(-10);
							transform: rotate(-180deg);
						}
					}

					&__sec {
						margin-top: rem-calc(-15);
						margin-bottom: rem-calc(18);
					}
				}
			}
		}
		// END. &__group

		&__title {
			position: relative;
			margin-bottom: 0;
			padding: rem-calc(27 0);
			color: #565656;

			// 箭頭(手機板的箭頭)
			&::before {
				position: absolute;
				top: 50%;
				right: 0;
				display: block;
				transform: translateY(-50%);
				transition: transform 0.3s;
			}
		}
	}

	// Medium 以上攤平
	@include breakpoint(medium) {
		--padding-x: #{rem-calc(20)};

		display: flex;
		flex-wrap: wrap;
		margin-right: calc(var(--padding-x) * -1);
		margin-left: calc(var(--padding-x) * -1) !important; // 避免被 Foundation no-bullet 蓋掉

		&__group {
			padding-right: var(--padding-x);
			padding-left: var(--padding-x);
			width: 33.33%;

			// Border (2021/08/25 移除，不同於設計稿樣式)
			// &:not(:last-child) {
			// 	border-right: var(--border);
			// }
		}

		&__title {
			margin-bottom: rem-calc(11);
		}

		&__link {
			--padding-y: #{rem-calc(8)};

			padding: var(--padding-y) 0;
			color: $body-font-color;
		}
	}

	@include breakpoint(medium only) {
		margin-bottom: rem-calc(-40);

		&__group {
			margin-bottom: rem-calc(40);

			&:nth-child(3n) {
				border-right: 0 none;
			}
		}
	}

	@include breakpoint(large) {
		&__group {
			width: (100% / 6);
		}
	}

	@include breakpoint(xlarge) {
		&__link {
			--padding-y: #{rem-calc(6)};

			font-size: rem-calc(16);
		}
	}
}
// END. .ft-sitemap

// -------------------------------------------------------

// start 停機公告
.announcement {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1500;
	background: $black;
	padding: rem-calc(10 0);
	// transform: translateY(-100%);
	transition: 0.5s;
	animation: stopToShow 1s;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem-calc(0 15);
		@include breakpoint(large) {
			padding: rem-calc(0 100);
		}
	}

	&.is-show {
		transform: translateY(0);
		transition: 0.5s;
	}

	&.is-hide {
		transform: translateY(-100%);
		transition: 0.5s;
	}

	&_p {
		font-size: rem-calc(16);
		line-height: 1.5;
		color: $white;
		margin-bottom: 0;
		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}

	.close-btn {
		width: rem-calc(30);
		height: rem-calc(30);
		border-radius: 50%;
		background: $visited-green;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin-left: rem-calc(15);
		cursor: pointer;
	}

	.be-icon {
		color: $black;
		font-size: rem-calc(14);
	}
}

@keyframes stopToShow {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}
// end 停機公告

// -------------------------------------------------------

// ===== Footer - Bottom =====
.ft-bottom {
	padding: rem-calc(50 0);

	@include breakpoint(small only) {
		position: relative;
		margin-bottom: rem-calc(50);
		padding-top: rem-calc(50 - 24);
		padding-bottom: 0;
	}

	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
	}
}

// Footer - 公司資訊
.ft-info {
	@include breakpoint(medium) {
		margin-right: rem-calc(20);
	}

	a {
		color: $body-font-color;
	}

	&__title {
		@extend %ft-title;
	}

	&__address {
		margin-bottom: rem-calc(12);
		@include breakpoint(medium) {
			margin-bottom: rem-calc(4);
		}
	}

	&__info-box {
		@include breakpoint(medium) {
			display: flex;
			margin-right: rem-calc(-20);
			margin-left: rem-calc(-20);
		}
	}

	&__info-item {
		@include breakpoint(medium) {
			position: relative;
			padding-right: rem-calc(20);
			padding-left: rem-calc(20);

			&:not(:last-child) {
				&::after {
					content: '';
					position: absolute;
					top: 0.3em;
					right: 0;
					width: 1px;
					height: calc(100% / 1.6);
					background: currentColor;
				}
			}
		}
	}
}
// END .ft-info

// Footer -社群網路服務
.ft-sns {
	display: flex;
	margin-bottom: 0;

	@include breakpoint(small only) {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	@include breakpoint($xs-down) {
		position: static;
		margin-top: rem-calc(18);
	}

	&__item {
		&:not(:last-child) {
			margin-right: rem-calc(15);

			@include breakpoint(medium) {
				margin-right: rem-calc(30);
			}
		}
	}

	.square-box {
		@include breakpoint(small only) {
			width: rem-calc(34);
		}
	}

	.be-icon {
		@include breakpoint(medium) {
			font-size: rem-calc(28);
		}
	}
}

// We Chat Popup Style
.reveal-wechat {
	overflow: hidden;
	padding: 0;
	border: 0 none;

	@at-root %cont {
		padding: rem-calc(16);
	}

	&__hd {
		@extend %cont;

		color: $white;
		background: $primary-color;
	}

	&__title {
		font-size: rem-calc(24);
		font-weight: bold;
	}

	&__cont {
		@extend %cont;
	}

	.close-button {
		top: 1px;
		right: 1px;
		width: 68px;
		height: 68px;
		color: $white;

		// 2021/09/02 全部做完才發現的Bug，因為後來用 ::before, ::after 製作 X
		// 因已交出，先避免修改HTML
		span {
			display: none !important;
		}

		@include breakpoint-hover {
			transition: 0.3s;

			&:hover,
			&:focus {
				color: $primary-color;
				background: $white;
			}
		}
	}
}
// END .ft-sns

// Footer - Final
.ft-final {
	min-height: rem-calc(40);
	font-size: rem-calc(14);
	background: $black;

	@include breakpoint($xs-down) {
		font-size: rem-calc(12);
	}

	@include breakpoint(medium down) {
		padding-bottom: rem-calc(10);
	}

	&,
	a {
		color: $white;
	}

	a {
		@include breakpoint-hover {
			&:hover,
			&:focus {
				text-decoration: underline;
				color: $primary-color;
			}
		}
	}

	&__cont {
		display: flex;

		@include breakpoint(medium down) {
			justify-content: center;
			flex-direction: column-reverse;
		}

		@include breakpoint(large) {
			align-items: center;
			justify-content: space-between;
		}
	}
}

.ft-copyright {
	@include breakpoint(medium only) {
		text-align: center;
	}
}

// Link: 法律、隱私權、Cookie
.ft-link {
	--padding-x: #{rem-calc(4)};

	display: flex;
	margin-bottom: 0;
	margin-right: calc(var(--padding-x) * -1);
	margin-left: calc(var(--padding-x) * -1);
	list-style: none;

	@include breakpoint(small only) {
		justify-content: space-between;
	}

	@include breakpoint(medium only) {
		--padding-x: #{rem-calc(10)};

		justify-content: center;
	}

	@include breakpoint(large) {
		--padding-x: #{rem-calc(12)};
	}

	&__item {
		text-align: center;
	}

	&__link {
		display: block;
		padding: rem-calc(12) var(--padding-x);
		line-height: 1.2;
		text-decoration: underline;
	}
}
// END Footer - Final

/* [END] Footer */

// -------------------------------------------------------

/* Cookie box */
.notification-box {
	$root: &;

	position: fixed;
	z-index: 1500;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 0 10px rgba($black, 0.2);
	animation: cookieToShow 1s;

	&__cont {
		position: relative;
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);
		color: $white;
		// background: #fff;

		// Bg
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 100%;
			background: $primary-gradient;
			transform: translateX(-50%);
		}
	}

	.button {
		margin-bottom: 0;
		font-size: rem-calc(16);
	}

	&.is-hide {
		display: none;
	}

	&.to-hide {
		animation: cookieToHide 0.5s forwards;
	}

	@include breakpoint(small only) {
		font-size: rem-calc(14);

		&__cont {
			padding-right: rem-calc(15);
			padding-left: rem-calc(15);
		}

		&__btn-box {
			text-align: center;
		}
	}

	@include breakpoint(medium) {
		&__cont {
			display: flex;
			align-items: center;
		}

		&__text-box {
			flex: 1 1 0;
			margin-right: rem-calc(20);

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@keyframes cookieToShow {
		0% {
			transform: translateY(100%);
		}
	}

	@keyframes cookieToHide {
		100% {
			transform: translateY(100%);
		}
	}
}

// END Cookie box
