// ★內頁 不需要 @include，style.sass(layout)已經有
// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes

// --------------------------------------------------------

/* Tag */

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	letter-spacing: ps-letter-spacing(20);
	
	// UAT 8 - 1366(1200) ~ 1599 調整字型大小，縮小 10%
	@include breakpoint(1600) {
		font-size: rem-calc(18);
	}
}

img {
	pointer-events: none;
}

.pointer-events-auto {
	pointer-events: auto;
}

a {
	color: $primary-link;

	.be-icon {
		color: currentColor;
	}
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

// UAT 8 - 1366(1200) ~ 1599 調整字型大小，縮小 10%
@each $size, $headers in $header-styles {
	@include breakpoint-between(xlarge, 1599) {
		@each $header, $header-defs in $headers {
			$font-size-temp: 1rem;
			#{$header}, .#{$header} {
				$font-size-temp: rem-calc(map-get($header-defs, font-size));

				font-size: $font-size-temp * 0.9;
			}
		}
	}
}

ul li {
	&::marker {
		font-size: 0.8em;
	}
}



// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: darken($primary-color, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}


// No Selection | 無法反選
// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include selection-none;
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到　src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar;

	.custom-scrollbar {
		@include custom-scroll-bar(#d4d3d1, $white);
	}

	.custom-scrollbar-green {
		@include custom-scroll-bar();
	}
}

// 隱藏 ScrollBar（但 Scrollbar 的功能還在）
// 非 Overflow hidden
// IE 無法使用
// Ref: https://blog.hubspot.com/website/hide-scrollbar-css
@mixin scroll-bar-hide {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none; /* for Chrome, Safari, and Opera */
	}
}

// --------------------------------------------------------

/* Title */
// 因為標題標籤若設置顏色，導致後續設置標題顏色有點麻煩
// 所以目前採取不設置標題顏色(inherit)，改增加class的方式
.title-dark {
	color: $black;
}

/* Text */
%primary-color {
	color: $primary-color;

	&:visited {
		color: $visited-green;

		&:hover,
		&:focus {
			color: $primary-hover;
		}
	}
}

.text-em,
.primary-color {
	@extend %primary-color;

	a {
		@extend %primary-color;
	}
}

.text-white {
	&,
	a {
		color: $white;

		&:hover {
			opacity: 0.8;
		}
		
		&:visited {
			color: $visited-white;
		}
	}
}

.text-tip {
	font-size: em-calc(16 / 18);
	color: $primary-tip;
}

.text-thin {
	font-weight: 300;
}

.text-bold {
	font-weight: bold;
}

.text-underline {
	text-decoration: underline;
}

.text-uppercase {
	text-transform: uppercase;
}

// 網址最好給個 break all
.text-word-break-all {
	word-break: break-all;
}

.text-icon {
	display: inline-flex;
	align-items: center;

	.be-icon {
		font-size: percentage(35 / 18);
	}

	@include breakpoint-hover {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

// [Photoshop|PS] 行高使用 Auto 為 120%
// 'auto' line height in Photoshop is equal to 120% which in CSS is line-height: 1.2;
// Ref: https://martinwolf.org/before-2018/blog/2016/02/convert-typographic-properties-from-photoshop-to-css/
.line-height-auto {
	line-height: 1.2;
}



// 文字溢行
%clamp {
	--line-height: 1.6;

	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: 1.6;
	line-height: var(--line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.clamp {
	&,
	&-1 {
		@extend %clamp;

		display: block;
		max-width: 100%;
		white-space: nowrap;
	}

	@function limit-height-get($i, $line-height) {
		@return calc(1em * #{$line-height} * #{$i});
	}

	@for $i from 2 through 6 {
		&-#{$i} {
			@extend %clamp;

			-webkit-line-clamp: $i;
			max-height: limit-height-get($i, 1.6);
			max-height: limit-height-get($i, var(--line-height));
		}
	}
}

// --------------------------------------------------------

/* Image */

.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-rel {
	position: relative;
}

.img-deco {
	position: absolute;
	top: 0;
	left: 0;
}

/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

.js-photo-popup {
	cursor: zoom-in;
	pointer-events: auto;
}

// --------------------------------------------------------

/* Form */
%input-global {
	margin-bottom: rem-calc(4);
	background-color: rgba($white, 0.5);
	transition-duration: 0s;
	box-shadow: none;

	@include breakpoint(large) {
		--padding-x: #{rem-calc(30)};
		--padding-y: #{rem-calc(8)};

		padding: var(--padding-y) var(--padding-x);
		height: rem-calc(70);
		font-size: rem-calc(18);
	}

	@include breakpoint-hover {
		&:hover {
			border-color: rgba($primary-color, 0.6);
		}

		&:focus {
			border-color: $primary-color;
			box-shadow: none;
		}
	}
	// color: $black;
}

%input-height {
	height: rem-calc(48);

	@include breakpoint(large) {
		height: rem-calc(70);
	}
	// color: $black;
}

#{text-inputs()} {
	@extend %input-global;
	@extend %input-height;
}

select {
	@extend %input-global;
	@extend %input-height;

	@include select-style; // 寫於 _variable_mixin_function
}

textarea {
	@extend %input-global;

	@include custom-scroll-bar(#d4d3d1, $white);

	min-width: 100%;
	min-height: rem-calc(200);

	@include breakpoint(large) {
		--padding-y: #{rem-calc(30)};

		min-height: rem-calc(320);
	}
}

.form-group {
	$root: &;

	margin-bottom: rem-calc(20);
	
	@include breakpoint(medium) {
		margin-bottom: rem-calc(40);
	}

	&__error {
		display: none;
		font-size: rem-calc(14);
		color: $primary-error;
		
		@include breakpoint(medium) {
			font-size: rem-calc(15);
		}
	}

	&.is-error {
		#{$root} {
			&__error {
				display: block;
			}
		}
	}
}

// --------------------------------------------------------

/* Table */
table {
	thead,
	tbody,
	tfoot {
		background: transparent;
		border: 0 none;
	}

	thead {
		tr {
			color: $white;
			background-image: $table-primary; // 漸層
			background-attachment: fixed; // tr 用漸層 iOS 智慧型裝置會出事，要增加此項（Android 未知）

			&:not(:first-child) {
				color: $white;
				background: $table-gray;
				
				td,
				th {
					padding: rem-calc(14.2 10);
				}
			}
		}

		td,
		th {
			line-height: 1.2;
		}
	}

	tbody {
		tr {
			background: $table-odd-bg;

			&:nth-child(2n) {
				background: transparent;
			}

			&:last-child {
				border-bottom: 1px solid $border-color-dark;
			}

			&:hover {
				background: $table-hover-bg;
			}
		}
	}

	th,
	td {
		@include breakpoint($xs-down) {
			font-size: rem-calc(14);
		}

		@include breakpoint(medium) {
			padding: rem-calc(20);
		}

		@include breakpoint(large) {
			padding: rem-calc(35 20);
		}
	}

	.tfoot {
		font-weight: bold;
	}

	// 左右擁有padding的表格
	// 要手動調整表格的寬度，不然高機率破版 <====== 重要
	// 上稿區不要使用
	&.table-x-padding {
		th,
		td {
			@include breakpoint(large only) {
				padding-right: rem-calc(10);
				padding-left: rem-calc(10);
			}

			@include breakpoint(xlarge) {
				$padding-x: $table-padding-x;
	
				&:first-child {
					padding-left: $padding-x;
					// width: 20%;
				}

				&:last-child {
					padding-right: $padding-x;
				}
			}
		}
	}
}

.table-wrapper {
	@include breakpoint(medium down) {
		position: relative;

		// 滾動提示遮罩
		&::before,
		&::after {
			$unit-menu-bg: #fff;

			--gradient-color: #{hex-to-rgb($unit-menu-bg)};

			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			display: none;
			width: #{rem-calc(100)};
			height: 100%;
			background: linear-gradient(to right, rgba(var(--gradient-color), 0) 0%, rgba(var(--gradient-color), 1) 100%);
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s;
		}

		// 左遮罩
		&::before {
			left: 0;
			transform: scale(-1);
		}

		// 右遮罩
		&::after {
			right: 0;
		}

		&.has-scroll {
			&::before,
			&::after {
				display: block;
			}
		}

		&:not(.is-scroll-start) {
			&::before {
				opacity: 1;
			}
		}

		&:not(.is-scroll-end) {
			&::after {
				opacity: 1;
			}
		}

		&__scroll-area {
			overflow: auto;
		}

		table {
			width: rem-calc(960);
		}
	}

	@include breakpoint(small only) {
		// 滾動提示遮罩
		&::before,
		&::after {
			width: #{rem-calc(20)};
		}
	}
}

@include breakpoint(small only) {
	// 手機板破格的排版方式（需要配合 data-title 顯示標題）
	// 加在 table
	.table-collapse {
		thead {
			display: none;
		}

		tr {
			display: block;
			padding-top: rem-calc(8 + 10);
			padding-bottom: rem-calc(10 + 10);
		}

		tbody {
			tr {
				&:first-child {
					border-top: 1px solid $border-color-dark;
				}
			}
		}

		th,
		td {
			display: block;
			line-height: 1.2;

			&[data-title] {
				&::before {
					content: attr(data-title);
					display: block;
					margin-bottom: rem-calc(4);
					color: $primary-color;
				}
			}
		}
	}

	// 標題會浮在前方的樣式加在
	// table
	// 不用 &.table-title-absolute 避免 CSS 層級過高，內頁樣式不好改
	.table-title-absolute {
		th,
		td {
			--padding-left: 5em;

			position: relative;
			padding-left: var(--padding-left);

			&::before {
				position: absolute;
				top: 0.5em;
				left: rem-calc(15);
				width: calc(var(--padding-left) - #{rem-calc(15 * 2)});
				word-break: break-all;
				word-break: break-word;
			}
		}
	}

	// 強調第一格，將第一格轉為綠色
	.table-em-first {
		tr {
			margin-bottom: rem-calc(10);
			padding-top: 0;
		}

		th,
		td {
			&:first-child {
				margin-bottom: rem-calc(18);
				padding: rem-calc(14 15);
				line-height: 1;
				color: $white;
				background: $primary-gradient;

				&[data-title] {
					&::before {
						display: inline-block;
						margin-bottom: 0;
						margin-right: rem-calc(10);
						font-weight: bold;
						color: $white;
					}
				}
			}
		}
	}
}

// --------------------------------------------------------

/* Flex */

.d-flex {
	display: flex;
}

// --------------------------------------------------------

/* Section */
.main-container {
	position: relative;
	padding-top: rem-calc(44);

	@include breakpoint(large) {
		padding-top: rem-calc(80);
	}

	@media (min-width: $hd-menu-bp) {
		padding-top: rem-calc(80 + 40);
	}
}



// 區塊內容
.sec {
	--padding-y: #{rem-calc(40)};

	padding-top: var(--padding-y);
	padding-bottom: var(--padding-y);

	@include breakpoint(medium) {
		--padding-y: #{rem-calc(80)};
	}

	@include breakpoint(large) {
		--padding-y: #{rem-calc(120)};
	}

	&:last-child,
	&:last-of-type {
		&:not(.sec-bg) {
			padding-bottom: calc(var(--padding-y) * 2);
			
			@include breakpoint(medium) {
				padding-bottom: var(--padding-y);
			}
		}
	}

	&.pt-half {
		padding-top: calc(var(--padding-y) / 2);
	}

	&.pb-half {
		padding-bottom: calc(var(--padding-y) / 2);
	}
}



.sec-block {
	word-break: break-all;
	word-break: break-word;

	&:not(:last-child) {
		margin-bottom: rem-calc(40);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(70);
		}
	}

	&.margin-large {
		&:not(:last-child) {
			margin-bottom: rem-calc(70);
	
			@include breakpoint(medium) {
				margin-bottom: rem-calc(120);
			}
		}
	}

	&__title {
		margin-bottom: em-calc(17 / 26);
		// text-transform: uppercase;
	}

	p {
		margin-bottom: em-calc(24 / 18);
	}
}



// 區塊標題
.sec-title {
	$root: &;

	margin-bottom: rem-calc(32);
	
	@include breakpoint(medium) {
		margin-bottom: rem-calc(52);
	}

	@at-root %title-bar {
		--skew: 15deg;
		--skew-minus: calc(var(--skew) * -1); // 負數

		position: relative;
		margin-bottom: em-calc(21 / 24);
		padding-left: percentage(35 / 50) / 100% * 1em;
		font-weight: bold;
		// text-transform: uppercase;
		letter-spacing: ps-letter-spacing(60);
		color: $black;
		transform: skew(var(--skew-minus) , 0);
		transform-origin: left bottom;

		// 標題前方的 Bar
		&::before {
			content: '';
			position: absolute;
			top: em-calc(10 / 24) / 2;
			left: 0;
			display: block;
			width: 3px;
			height: calc(100% - #{em-calc(10 / 24)});
			background: primary-gradient(0, #4fca8f);
			transform: skew(var(--skew) , 0) rotate(var(--skew));
		}

		@include breakpoint(medium) {
			margin-bottom: em-calc(20 / 50);

			&::before {
				width: 5px;
			}
		}

		@include breakpoint(medium only) {
			font-size: rem-calc(36);
		}

		// 文字沒有斜，也沒有Bar
		&.normal {
			padding-left: 0;
			transform: none;

			&::before {
				display: none;
			}
		}

		// 文字沒有斜，Bar有斜
		&.no-skew {
			transform: none;

			&::before {
				transform: rotate(var(--skew));
			}
		}
	}

	&__title {
		@extend %title-bar;
	}

	// 具有下底線樣式，如 investor-financials-history
	&.border {
		margin-bottom: rem-calc(32);
		border-bottom: 1px solid $border-color-dark;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(52);
		}

		@include breakpoint(xlarge) {
			margin-bottom: rem-calc(73);
		}
	}
}

// 單獨使用左邊有 bar 的標題
.title-bar {
	@extend %title-bar;
}
// END. .sec-title


.sec-text-box {
	margin-bottom: rem-calc(40);
	
	@include breakpoint(large) {
		&__desc {
			margin-bottom: rem-calc(36);
			width: 80%;
		}
	}

}



.article-cont {
	// 設定 H1 ~ H6
	@at-root %title-font-color {
		margin-bottom: rem-calc(16);
		// margin-bottom: em-calc(28 / 26);
		// text-transform: uppercase;
		letter-spacing: ps-letter-spacing(20);
		color: $black;
	}

	@for $i from 1 to 6 {
		h#{$i},
		.h#{$i} {
			@extend %title-font-color;
		}
	}

	h2,
	.h2 {
		letter-spacing: ps-letter-spacing(60);
	}

	// 設定 ul ol li
	ul,
	ol {
		margin-bottom: rem-calc(32);

		ul,
		ol {
			margin-top: rem-calc(10);
		}
	}

	ul {
		// 第二層：方塊
		ul {
			list-style: none;

			li {
				position: relative;
				padding-left: rem-calc(3);

				&::before {
					@include font-icon-checked;

					position: absolute;
					left: -2em;
					top: 1.3em;
					transform: translateY(-50%);
					font-size: 60%;
				}
			}
			
			// 第三層：打勾
			ul {
				list-style-type: square;

				li {
					position: static;
					padding-left: 0;

					&::before {
						display: none;
					}
				}
			}
		}
	}

	li {
		margin-bottom: rem-calc(10);
	}

	p {
		margin-bottom: em-calc(24 / 18);
	}

	a {
		text-decoration: underline;

		&:visited {
			color: $visited-blue;

			&:hover,
			&:focus {
				color: $primary-hover;
			}
		}
	}

	img {
		pointer-events: inherit;
		cursor: zoom-in;
	}

	table {
		tr {
			&:first-child {
				font-weight: bold;
				color: $white;
				background: $table-primary;
				background-attachment: fixed; // tr 用漸層 iOS 智慧型裝置會出事，要增加此項（Android 未知）
			}
		}
	}
}




// --------------------------------------------------------



// 一般有背景的區塊
.sec-bg {
	position: relative;
}

// 背景區域外層，一定要加 .sec-bg (為了設定 position relative)
.bg-box {
	$root: &;

	position: absolute;
	z-index: -5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	// 背景圖
	&__bg {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		pointer-events: none;
	}

	// 需要設定底色，所以要限制 overflow hidden 情況
	&.bg-overflow-hidden {
		overflow: hidden;
	}

	// 最底下區塊的一整片的背景物件(會跨到Footer)
	// 如 Index, Career 單元
	&.bg-ft {
		// 內容區域遮罩 => 改寫於 Footer ::before var(--ft-mask-top-height) 的部分
		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	z-index: -1;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 500px;
		// 	background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 0.3) 100%);
		// }
		
		// 背景圖
		#{$root} {
			&__bg {
				bottom: calc(var(--ft-mask-height) * -1);
				left: 0;
				height: 200vh;
				background-position: bottom center;
				background-size: 100%;
			}
		}

		// 需要設定底色，所以要限制 overflow hidden 情況
		&.bg-overflow-hidden {
			height: calc(100% + var(--ft-mask-height));

			// &::after {
			// 	bottom: var(--ft-mask-height);
			// }

			#{$root} {
				&__bg {
					bottom: 0;
				}
			}
		}
	}

	// 具有底色的樣式
	&.bg-color {
		// 底色設定在 before，避免蓋過圖片
		&::before {
			content: '';
			position: absolute;
			z-index: -5;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}



// 藍底網格背景
.bg-blue-wire {
	// 底色
	&::before {
		background-color: #edf7fb;
	}

	.bg-box {
		&__bg {
			&.loaded {
				background-image: url('../images/bg-blue-wire.jpg');
			}
		}
	}
}



// 內頁主要左右方格背景
.bg-global {
	--top: #{rem-calc(44 + 180)}; // Header(44) + Inside Page Banner Height(180)

	position: absolute;
	z-index: -10;
	top: var(--top);
	left: 0;
	width: 100%;
	height: calc(100% + #{rem-calc(250)} - var(--top)); // 250: Footer 區塊
	pointer-events: none;
	background-image: url('../images/bg-repear-left.svg'), url('../images/bg-repear-right.svg');
	background-position: left top, right top;
	background-repeat: repeat-y;
	background-size: rem-calc(250 / 2);

	@include breakpoint(medium) {
		--top: #{rem-calc(44 + 250)}; // Header(44) + Inside Page Banner Height(250)

		background-size: rem-calc(250);
	}

	@include breakpoint(large) {
		--top: #{rem-calc(40 + 80 + 330)}; // Header Top(40) + Header Main (80) + Inside Page Banner Height(330)
	}
}



// --------------------------------------------------------



// Page Banner
.page-banner {
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding-top: rem-calc(30);
	padding-bottom: rem-calc(30);
	min-height: rem-calc(180);
	background: #eee;
	text-shadow: 0 0 rem-calc(5) $white;

	// 黑背景（下滑淡出Banner用
	@include breakpoint(large) {
		&.loaded-content {
			background: $black;
			transition: background-color 0.3s 0.5s; // 避免剛進入時閃動，延遲換色
		}
	}

	@include breakpoint(medium) {
		min-height: rem-calc(250);
	}

	@include breakpoint(large) {
		min-height: rem-calc(330);
	}

	&__bg {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background-position: 68% center;
		background-size: cover;

		@include breakpoint(medium only) {
			// background-position: center 70%;
		}
	}

	&__text-box {
		// 讓標題實質上的浮在正中央 => 垂直置中
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			max-width: 80%;
			transform: translateY(-50%);
		}

		@include breakpoint(xxlarge) {
			max-width: calc(#{rem-calc(1440)} * 0.7);
		}
	}

	&__title {
		margin-bottom: 0;
		// text-transform: uppercase;
		letter-spacing: ps-letter-spacing(60);
		color: $black;

		@include breakpoint(medium) {
			display: flex; // 為了垂直置中
			align-items: center; // 為了垂直置中
			min-height: 1.2em * 2; // 至少兩行高
		}

		@include breakpoint(medium only) {
			$font-size-small: map-get(map-get(map-get($header-styles, small), h1), 'font-size');
			$font-size-medium: map-get(map-get(map-get($header-styles, medium), h1), 'font-size');

			font-size: rem-calc(($font-size-medium + $font-size-small) / 2);
		}
	}

	// Banner Hover 後呈現的效果
	&__hover-effect {
		$hover-color: $white;

		--hover-color: #{hex-to-rgb($hover-color)}; // 要帶入rgba，所以hex要轉為rgb

		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		display: none;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);

		.cls-1 {
			fill: rgba(var(--hover-color), 0.78);
		}

		.cls-2 {
			fill: rgba(var(--hover-color), 0.86);
		}

		.cls-3 {
			fill: rgba(var(--hover-color), 0.92);
		}

		@include breakpoint-hover {
			display: block;
		}
	}
}


// 麵包屑
// Foundation component
.breadcrumbs {
	a {
		color: $black;
	}

	li {
		font-size: rem-calc(12);
		text-transform: initial;
		color: $black;

		&:not(:last-child) {
			&::after {
				margin-right: rem-calc(6);
				margin-left: rem-calc(2);
				color: $black;
			}
		}

		&:last-child {
			color: $primary-color;
		}

		@include breakpoint(medium) {
			font-size: rem-calc(14);
		}

		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}
}



// --------------------------------------------------------



// 內頁主要選單
.unit-menu {
	$root: &;
	$unit-menu-bg: #eee;

	position: relative;
	background: $unit-menu-bg;

	.grid-container {
		@include breakpoint(medium down) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	// Sticky
	&.is-sticky {
		padding-top: rem-calc(44);
		
		@include breakpoint(large) {
			padding-top: rem-calc(78);
		}

		#{$root} {
			// 為了 Fixed 設置的物件
			// 外層要保留位置監測
			&__inside {
				$el: &;

				position: fixed;
				z-index: 900;
				top: rem-calc(44);
				left: 0;
				width: 100%;
				box-shadow: 0 2px 7px rgba($black, 0.15);

				@include breakpoint(large) {
					top: rem-calc(80);
					transition: transform 0.3s;
				}

				@media (min-width: $hd-menu-bp) {
					@at-root .is-scroll-up #{$el} {
						transform: translateY(rem-calc(40));
					}
				}
			}

			&__link {
				@include breakpoint(large) {
					--padding-y: #{rem-calc(22)}; // 12 === 40px height

					transition: 0.3s;
				}
			}
		}
	}

	&__inside {
		position: relative;
		background: $unit-menu-bg;

		// 左側綠色區塊
		&::before {
			$el: &;

			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: none;
			width: rem-calc(60);
			height: 100%;
			background: $primary-color;
			pointer-events: none;

			@include breakpoint(xxlarge) {
				width: calc((100vw - #{rem-calc(1440)}) / 2 + #{80px * tan(30deg)});
			}

			@at-root #{$root} {
				&.has-overview {
					#{$el} {
						@include breakpoint(large) {
							display: block;
						}
					}
				}
			}
		}

	}

	&__cont {
		@include breakpoint(large) {
			position: relative;
		}

		// 滾動提示遮罩
		&::before,
		&::after {
			--gradient-color: #{hex-to-rgb($unit-menu-bg)};

			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			display: none;
			width: #{rem-calc(40)};
			height: 100%;
			background: linear-gradient(to right, rgba(var(--gradient-color), 0) 0%, rgba(var(--gradient-color), 1) 100%);
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&::before {
			left: 0;
			transform: scale(-1);

			@include breakpoint(large) {
				display: none !important;
			}
		}

		&::after {
			right: 0;
		}

		@at-root #{$root} {
			&.has-scroll {
				#{$root} {
					&__cont {
						&::before,
						&::after {
							display: block;
						}
					}
				}
			}

			&:not(.is-scroll-start) {
				#{$root} {
					&__cont {
						&::before {
							opacity: 1;
						}
					}
				}
			}

			&:not(.is-scroll-end) {
				#{$root} {
					&__cont {
						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__list {
		$el: &;

		flex-wrap: inherit;
		overflow: auto;
		white-space: nowrap;
		
		@include breakpoint(large) {
			margin-left: rem-calc(-25);
		}

		// 滑鼠拖曳
		&.is-drag {
			cursor: grabbing;
			cursor: -webkit-grabbing;
		}

		@include breakpoint-hover {
			// Hide ScrollBar (Not Overflow Hidden)
			@include scroll-bar-hide;

			@at-root #{$root} {
				.has-scroll {
					#{$el} {
						cursor: pointer;
					}
				}
			}
		}
	}

	// 為了要給 fake-overview 使用，所以用 placeholder selector
	// <a> 使用
	@at-root %unit-menu-link {
		--padding-x: #{rem-calc(15)};
		--padding-y: #{rem-calc(15)};
		
		position: relative;
		padding: var(--padding-y) var(--padding-x);
		font-size: rem-calc(14);
		color: $body-font-color;

		// Active Bar
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: var(--padding-x);
			width: calc(100% - var(--padding-x) * 2);
			height: rem-calc(3);
			background: currentColor;
			transform: scaleX(0);
		}

		@include breakpoint(medium) {
			font-size: rem-calc(16);
		}

		@include breakpoint(large) {
			--padding-y: #{rem-calc(22)};
		}

		@include breakpoint(xxlarge) {
			--padding-x: #{rem-calc(25)};
			--padding-y: #{rem-calc(32)};
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $primary-color;

				&::before {
					transform: scaleX(1);
					transition: transform 0.3s;
				}
			}
		}
	}

	// 為了要給 fake-overview 使用，所以用 placeholder selector
	// 使用在概要(Overview)頁面項目（綠底）
	@at-root %unit-menu-link-overview {
		margin-right: rem-calc(15);
		padding-right: rem-calc(15 * 2);
		color: $white;

		&::before {
			width: calc(100% - var(--padding-x) * 3);
		}

		// 傾斜背景
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: $primary-color;
			transform: skew(-30deg);
		}

		.text {
			position: relative;
			z-index: 1;
		}

		@include breakpoint(large) {
			margin-right: rem-calc(40);
			padding-right: rem-calc(65);

			// Active Bar
			&::before {
				width: calc(100% - #{rem-calc(25 * 2 + 65 / 2 + 9)});
			}
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $white;
			}
		}
	}

	&__link {
		@include bem-tag {
			@extend %unit-menu-link;
		}

		// 使用在概要(Overview)頁面項目（綠底）
		&.overview {
			@extend %unit-menu-link-overview;
		}

		@at-root #{$root} {
			// 最外層放置含有 overview 的樣式
			&.has-overview {

				#{$root} {
					&__list {
						&::before {
							@include breakpoint(medium down) {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: #{rem-calc(15)};
								height: 100%;
								background: $primary-color;
								pointer-events: none;
							}
						}
					}

					&__item {
						@include breakpoint(large) {
							&:first-child {
								opacity: 0;
							}
						}
					}

					&__fake-overview {
						@include breakpoint(large) {
							display: block;
						}
					}
				}
			}
		}
	}

	// 有Overview 時，Large+ Fixed 在前方的物件(避免有滾動)
	&__fake-overview {
		@include bem-tag {
			display: none;

			@extend %unit-menu-link-overview;

			@include breakpoint(large) {
				position: absolute;
				z-index: 5;
				top: 0;
				left: calc(var(--padding-x) * -1 + #{rem-calc(6)});
				line-height: 1;
	
				&::after {
					width: calc(100% + #{rem-calc(100)});
				}

				&.is-active {
					&::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	&__item {
		// Active
		&.is-active {
			#{$root} {
				&__link {
					color: $primary-color;
					background: transparent;

					&::before {
						transform: scaleX(1);
					}

					&.overview {
						color: $white;
					}
				}
			}
		}
	}

	// 操作按鈕
	.btn-arrow {
		--size: #{rem-calc(45)};

		position: absolute;
		z-index: 5;
		top: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: var(--size);
		height: var(--size);
		color: $body-font-color;
		border: 1px solid transparent;
		border-radius: 100%;
		cursor: pointer;
		transform: translateY(-50%);
		

		&.left {
			left: calc(var(--size) * -1 - #{rem-calc(10)});
		}

		&.right {
			right: calc(var(--size) * -1 - #{rem-calc(10)});
		}

		@include breakpoint(large down) {
			display: none;
		}

		@include breakpoint-hover(false) {
			display: none;
		}

		@include breakpoint-hover {
			&:hover,
			&:focus {
				color: $primary-color;
				border-color: currentColor;
			}
		}

		@at-root #{$root} {
			&.has-overview {
				.btn-arrow.left {
					color: $white;
				}
			}
		}

		&.disabled {
			display: none;
		}
	}
}
// END. .unit-menu


// 有綠色選項的分隔
.sec-bar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem-calc(40);
	// border-bottom: 2px solid $primary-color; // 以 ::before 提代

	// 代替 Border-bottom
	// 視覺稿線的圖層在下方
	// 如果圖層順序在上方或列於下方（border-bottom）的方式
	// 會讓 select 漸層會很明顯（手機板尤其如此）
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $primary-color;
	}
	
	@include breakpoint(large) {
		margin-bottom: rem-calc(80);
	}

	&__left-box {
		color: $primary-color;

		@include breakpoint(small only) {
			font-size: rem-calc(14);
		}
	}

	&__select {
		margin-bottom: 0;
		padding-left: rem-calc(10);
		font-weight: bold;
		color: $white;
		background-image: url(#{$select-arrow-white});
		
		@include breakpoint($xs-down) {
			padding-right: rem-calc(35);
			padding-left: rem-calc(5);
			background-position-x: right 8px;
		}

		@include breakpoint(medium) {
			padding-left: rem-calc(20);
			height: rem-calc(56);
			font-size: rem-calc(26);
		}

		&,
		&:focus {
			background-color: transparent;
			border: 0 none;
			box-shadow: none;
		}

		option {
			font-size: rem-calc(16);
			color: $body-font-color;
		}
		
		&-wrapper {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				width: calc(100% + #{rem-calc(20)});
				height: 100%;
				background: $primary-gradient;
				clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%);
			}
		}
	}

	// 返回鍵
	.button-back {
		--padding-y: #{rem-calc(6)};

		display: block;
		margin-bottom: rem-calc(10);
		padding-top: var(--padding-y);
		padding-bottom: var(--padding-y);
		font-weight: normal;
		color: $primary-color;

		@include breakpoint(medium) {
			--padding-y: #{rem-calc(11)};

			margin-bottom: rem-calc(19);
		}
	}
}
// END. .sec-bar


// --------------------------------------------------------

/* Foundation */

// 水平 Padding (Medium+ 避免左右寬度過窄)
.grid-container {
	@include breakpoint(medium) {
		--padding-x: #{rem-calc(40 + 15 * 2)};

		padding-right: var(--padding-x);
		padding-left: var(--padding-x);
	}
	
	@include breakpoint(xlarge) {
		--padding-x: #{rem-calc(50 + 25 * 2)};
	}
}



/* Reveal */
.reveal {
	// clip-path 點
	// 1 ------- 2
	// |         |
	// |         |
	// |         3
	// |       ╱
	// 5-----4
	$cornor-triangle-height: 25px; // 右下三角形的高

	$square-point-1: 0 0;
	$square-point-2: 100% 0;
	$square-point-3: 100% calc(100% - #{$cornor-triangle-height});
	$square-point-4: calc(100% - #{sin(45deg) * ($cornor-triangle-height / cos(45deg)) }) 100%; // 因為要三角函數計算，所以更改角度後，無法自動產生數字
	$square-point-5: 0 100%;

	clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4}, #{$square-point-5});
	animation: showModal 0.3s;

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		
		@include breakpoint(medium) {
			min-height: rem-calc(400 - 16 * 2);
		}
	}

	&__title {
		margin-bottom: rem-calc(8);
		width: 100%;
		// text-transform: uppercase;
		color: $black;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(16);
		}
	}

	&__text-box {
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__icon {
		margin-bottom: rem-calc(20);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(45);
		}
	}

	.close-button {
		top: 10px;
		right: 10px;
		width: 48px;
		height: 48px;
		color: #565656;

		@include breakpoint(medium) {
			top: 30px - 13;
			right: 30px - 13;
		}

		&::before,
		&::after {
			$size: 32px;

			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			margin-left: ($size / 2 * -1);
			width: $size;
			height: 2px;
			background: currentColor;
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(135deg);
		}

		@include breakpoint-hover {
			transition: transform 0.3s;

			&:hover,
			&:focus {
				color: $primary-color;
				transform: rotate(90deg);
			}
		}
	}
}

.reveal-msg {
	@include breakpoint(small only) {
		left: 5%;
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
		width: 90%;
		height: auto;
		min-height: 0;
	}

	@include breakpoint(large) {
		width: rem-calc(785 - 16 * 2);
	}
}

@keyframes showModal {
	from {
		opacity: 0;
	}
}

// --------------------------------------------------------

/* Button */
.button {
	position: relative;
	padding: rem-calc(12 30);
	min-width: rem-calc(186);
	font-size: rem-calc(16);
	background-image: $primary-gradient;
	border: 0;

	@include breakpoint(medium) {
		padding: rem-calc(18 34);
		font-size: rem-calc(18);
	}

	&.white {
		color: $primary-color;
		background: $white;
	}

	&.hollow {
		background: none;
	}
}

// 外層有傾斜的按鈕
// 避免影響到 Foundation 主要 Button 樣式，寫於Global
// ★重要：內層一定要有 .text 放置顯示文字
.button-global {
	$h: 40px;
	$skew: 15deg;

	--skew: #{$skew};

	// 三角函數參考：https://zh.numberempire.com/right_triangle_calculator.php
	// 為了要對齊要位移的距離，因為要三角函數計算，所以更改角度後，無法自動產生數字
	--move-margin: #{(sin($skew) * ($h / cos($skew))) / 2};

	margin-left: var(--move-margin);
	font-weight: bold;
	// text-transform: uppercase;
	transform: skew(calc(var(--skew) * -1));

	@include breakpoint-hover {
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 1px;
			left: 1px;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			background: $white;
			transform: scaleX(0);
			transform-origin: right center;
			transition: 0.3s transform;
		}

		&:hover,
		&:focus {
			color: $primary-color;

			&::before {
				transform: scaleX(1);
				transform-origin: left center;
			}
		}

		&.white {
			&::before {
				background: $primary-color;
			}

			&:hover,
			&:focus {
				color: $white;
			}
		}
	}

	// 文字(要傾斜回來)
	.text {
		position: relative;
		z-index: 1;
		display: inline-block;
		transform: skew(var(--skew));

		@include breakpoint-hover {
			transform: skew(var(--skew)) translate3d(0, 0, 0);
		}
	}

	// 傾斜角度：30度
	&.angle-30 {
		$skew: 30deg;

		--skew: #{$skew};
		--move-margin: #{(sin($skew) * ($h / cos($skew))) / 2};
	}

	// 因為 font-size 變更 $h 會跟著變動
	@include breakpoint(medium) {
		$h: 54px;
		$skew: 15deg;

		--skew: #{$skew};
		--move-margin: #{(sin($skew) * ($h / cos($skew))) / 2};

		&.angle-30 {
			$skew: 30deg;
	
			--skew: #{$skew};
			--move-margin: #{(sin($skew) * ($h / cos($skew))) / 2};
		}
	}
}


// 有箭頭的按鈕
%btn-arrow {
	--move-x: 0; // 箭頭位移的程度

	font-weight: bold;

	@include breakpoint-hover {
		&:hover,
		&:focus {
			--move-x: 20px;
		}
	}
}

// 箭頭物件
%button-arrow-after {
	@include font-icon-arrow-right;

	transform: translateX(var(--move-x));
	transition: 0.3s;
}

// 按鈕 + 箭頭
.button-arrow {
	@extend %btn-arrow;

	.text {
		&::after {
			@extend %button-arrow-after;

			@include breakpoint(medium down) {
				display: none;
			}
		}
	}
}

// 文字 + 箭頭
.text-arrow {
	@extend %btn-arrow;

	&::after {
		@extend %button-arrow-after;
	}

	&.reverse {
		&::before {
			@include font-icon-arrow-left;

			margin-right: rem-calc(18);
			margin-left: rem-calc(-6);
			
			@include breakpoint(large) {
				transform: translateX(calc(var(--move-x) * -1));
				transition: 0.3s;
			}
		}

		&::after {
			display: none;
		}
	}
}

// 因為 Foundation 的 Component 不符合使用，所以未使用 Foundation 的
.button-group {
	--margin-x: #{rem-calc(34 / 2)};

	margin-right: calc(var(--margin-x) * -1);
	margin-left: calc(var(--margin-x) * -1);

	&__item {
		display: inline-block;
		margin-bottom: rem-calc(10);
		margin-right: var(--margin-x);
		margin-left: var(--margin-x);

		@include breakpoint(large) {
			margin-bottom: rem-calc(35);
		}
	}

	.button {
		min-width: rem-calc(190);

		@include breakpoint(medium) {
			min-width: rem-calc(240);
		}
	}
}

// --------------------------------------------------------

/* Square Box | (缺角)正方形框 + Icon */
.square-box {
	$root: &;

	// clip-path 點
	// 1 ------- 2
	// |         |
	// |         3
	// |       ╱
	// 5-----4
	$square-point-1: 0 0;
	$square-point-2: calc(100% + 1px) 0;
	$square-point-3: calc(100% + 1px) calc(70% - 1px);
	$square-point-4: calc(70% - 1px) calc(100% + 1px);
	$square-point-5: 0 calc(100% + 1px);
	
	--border-color: #{$primary-color};

	position: relative;
	display: block;
	overflow: hidden;
	width: rem-calc(40);
	color: $primary-color;
	background: rgba($white, 0.8);
	border: 1px solid var(--border-color);
	clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4}, #{$square-point-5});

	@include bem-tag(button) {
		cursor: pointer;
	}

	// 撐高度
	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	// 右下斜線
	&::after {
		content: '';
		position: absolute;
		bottom: -1px;
		left: calc(70% + 0.5px);
		width: calc(30% * 1.414 + 100%);
		height: 1px;
		background: var(--border-color);
		transform: rotate(-45deg);
		transform-origin: left bottom;
	}

	.be-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	// 漸層底
	&.gradient {
		--border-color: #{$primary-color};

		color: $white;
		background: $primary-gradient;
	}

	@include breakpoint(medium) {
		width: rem-calc(50);

		.be-icon {
			font-size: rem-calc(22);
		}
	}

	@include breakpoint-hover {
		// Hover 後的填色
		&::before {
			background: $primary-color;
			transform: scaleX(0);
			transform-origin: right center;
			transition: transform 0.3s;
		}

		&:hover,
		&:focus {
			color: $white;
			
			&::before {
				transform: scaleX(1);
				transform-origin: left center;
			}
		}

		// 漸層底
		&.gradient {
			transition: background-color 0.3s;

			&::before {
				background: $white;
			}

			&:hover,
			&:focus {
				--border-color: #{$primary-color};

				color: $primary-color;
			}
		}
	}
}

// --------------------------------------------------------

/* 缺角矩形／圖 */

/* 使用方式參考結構：
[1] 只有圖片沒有 Hover 後的樣式 (參考 carrer.pug)
.img-box.box-lack-cornor
	.box-lack-cornor__cont
		img.box-lack-cornor__img

[2] 有 Hover 後的樣式 (參考 investor-financials-annual.pug)
.img-box.box-lack-cornor
	.box-lack-cornor__shadow.show-for-hover
	.box-lack-cornor__cont
		img.box-lack-cornor__img(src="圖片路徑")
		.box-lack-cornor__inside
			.box-lack-cornor__hover-line
				svg.svg-bg.img-deco(width="254"): use(xlink:href="#img-hover-bg")
				.img.lazyload(data-src="圖片路徑")
				svg.svg-line.img-deco(width="254"): use(xlink:href="#img-hover-line")
※有Hover，頁面上要增加相關的 SVG DOM：
//- Hover 呈現樣式需求 SVG
+box-lack-cornor-svg-hover-line

◎ 需調整
	* .img-box.box-lack-cornor 的 ::before 以 padding-top 決定圖片比例（決定高度）
	* .img-box.box-lack-cornor 設定寬度
*/

.box-lack-cornor {
	$root: &;

	// clip-path 點
	// 1 ------- 2
	// |         |
	// |         |
	// |         |
	// |         3
	// |       ╱
	// 5-----4
	$cornor-triangle-height: 45px; // 右下三角形的高

	$square-point-1: 0 0;
	$square-point-2: 100% 0;
	$square-point-3: 100% calc(100% - #{$cornor-triangle-height});
	$square-point-4: calc(100% - #{sin(30deg) * ($cornor-triangle-height / cos(30deg)) }) 100%; // 因為要三角函數計算，所以更改角度後，無法自動產生數字
	$square-point-5: 0 100%;

	// 要寫 before 的 padding-top 去設定高
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	overflow: visible;
	max-width: 100%;
	
	&__cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #eee;
		clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4}, #{$square-point-5});
	}

	&__inside {
		display: none;
	}

	&__shadow {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			box-shadow: $primary-shadow;
			@include breakpoint-hover {
				transition: box-shadow 0.3s;
			}
		}
		
		&::before {
			top: 0;
			width: 100%;
			height: calc(100% - #{rem-calc(46)});
		}
		
		&::after {
			$h: 46px;
			$skew: 30deg;

			--move-margin: #{(sin($skew) * ($h / cos($skew)))};

			bottom: 0;
			width: calc(100% - 46px);
			width: calc(100% - var(--move-margin) - 9px);
			height: rem-calc(54);
		}

		&.show-for-hover {
			display: none;
		}
	}

	@include breakpoint-hover {
		&__shadow {
			&.show-for-hover {
				display: block;

				&::before,
				&::after {
					box-shadow: 0 0 0 rgba($shadow-color, 0.13);
				}
			}
		}

		&__inside {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			overflow: hidden;
			width: 100%;
			height: 100%;
		}
	
		&__hover-line {
			position: absolute;
			top: -1px;
			left: -1px;
			width: percentage(254 / 360);
			transform: translateY(-100%);
			transition: opacity 0.5s, transform 0.5s;
	
			&::before {
				@include before-img-size(254, 220);
			}
	
			.img {
				position: absolute;
				top: 0;
				left: 0;
				// width: 100%;
				// 為了要使用 clip-path objectBoundingBox ，需要設置 1:1 的外框
				// objectBoundingBox 的使用方式 https://stackoverflow.com/a/53448033
				width: percentage(220 / 254); // 為了要取得 1:1
				height: 100%;
				background-position: center -50%;
				opacity: 0.5;
				transition: background-position 0.5s;

				&.loaded {
					clip-path: url('#img-hover-clip');
					// clip-path: polygon(79px 117.9px, 165.6px 67.9px, 79px 18px, 78.2px 16.5px, 78.2px 0, 0 0, 0 62.6px, 77.7px 17.7px, 78.2px 18.5px, 78.2px 117.4px, 77.7px 118.2px, 0 73.3px, 0 174.3px, 78.2px 219.4px, 78.2px 119.4px);
				}
			}
	
			svg {
				width: 100%;
				height: 100%;
			}
		}

		&:hover,
		&:focus {
			#{$root} {
				&__shadow {
					&::before,
					&::after {
						box-shadow: $primary-shadow-hover;
					}
				}

				&__hover-line {
					transform: translateY(0);
				}

			}

			.img {
				background-position-y: 50%;
			}
		}
	}
}
// END. .box-lack-cornor

// --------------------------------------------------------

/* 圖片缺角 + 文字資訊 + hover 會出現 cube 物件 */
// 圖片搭配 .box-lack-cornor 使用
// 如：esg-report, investor-financials-annual
.img-info-box {
	$root: &;

	--width: #{rem-calc(450)}; // 設置 PC 版 4 grid 大小

	list-style: none;

	@include breakpoint(small only) {
		--gutter: #{rem-calc(10)};
		--col: 2;

		margin-right: calc(var(--gutter) / 2 * -1);
		margin-left: calc(var(--gutter) / 2 * -1);

		&__item {
			&.cell {
				margin-right: calc(var(--gutter) / 2);
				margin-left: calc(var(--gutter) / 2);
				width: calc(100% / var(--col) - var(--gutter)) !important;
			}
		}
	}

	&__item {
		margin-bottom: rem-calc(20);
		
		@include breakpoint(medium) {
			margin-bottom: rem-calc(65);
		}
	}

	&__link {
		display: block;
		margin-right: auto;
		margin-left: auto;
		width: var(--width);
		max-width: 100%;
		color: $black;
	}

	&__img-box {
		margin-bottom: rem-calc(24);
		margin-right: auto;
		margin-left: auto;
		width: var(--width);

		// 控制圖片大小
		&::before {
			@include before-img-size(360, 450, false);
		}
	}

	@include breakpoint-hover {
		&__link {
			&:hover,
			&:focus {
				@include box-lack-cornor-hover;

				color: $primary-color;
			}
		}
	}

	// 強調第一個項目，第一個項目 medium+ 為寬 100%
	&.em-first {
		#{$root} {
			&__item {
				&:first-child {
					--gutter: #{rem-calc(map-get($map: $grid-margin-gutters, $key: small))};
					
					width: calc(100% - var(--gutter)) !important;
					
					@include breakpoint(medium) {
						--gutter: #{rem-calc(map-get($map: $grid-margin-gutters, $key: medium))};
					}
				}
			}
		}
	}
}
// END .img-info-box

// --------------------------------------------------------

/* HEXAGON | 六角形 */
.hexagon-box {
	$hex-svg-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 146 169'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='23.123' y1='143.942' x2='122.878' y2='25.058' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.4' stop-color='%2304a672'/%3E%3Cstop offset='1' stop-color='%23a9f7b6'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath d='M144.42 125.866L73 167.232 1.581 125.866V43.134L73 1.768l71.42 41.366z' fill='url(%23a)'/%3E%3C/svg%3E");
	$hex-svg-border: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 146 169'%3E%3Cpath d='M144.42 125.866L73 167.232 1.581 125.866V43.134L73 1.768l71.42 41.366z' fill='none' stroke='%2304a672' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E");

	margin-right: auto;
	margin-left: auto;
	max-width: 120px;
	background-image: $hex-svg-solid;

	&::before {
		content: '';
		display: block;
		padding-top: percentage(4 / (2 * sqrt(3)));
	}

	&.hex-none {
		background-image: none;
	}

	&.hex-border {
		background-image: $hex-svg-border;
	}

	// Svg 物件
	.hex-svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}
// END .hexagon-box

// --------------------------------------------------------

/* 檔案列表 */
.file-list {
	@at-root %global-pd {
		padding-right: percentage($number: 1/ 12);
		padding-left: percentage($number: 1/ 12);
	}

	margin-bottom: rem-calc(100 - 20);

	&:last-child {
		margin-bottom: rem-calc(30);
	}

	&__title {
		@extend %global-pd;

		margin-bottom: em-calc(18 / 26);
		color: $black;
	}
	
	&__list {
		--border: 1px solid #{$border-color-dark};

		margin: 0;
		margin-bottom: rem-calc(20);
		border-top: var(--border);
		border-bottom: var(--border);
		list-style: none;
	}

	&__link {
		@extend %global-pd;

		--padding-y: #{rem-calc(11)};

		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		padding-top: var(--padding-y);
		padding-bottom: var(--padding-y);
		color: $body-font-color;

		&:visited {
			color: $visited-dark;
		}

		@include breakpoint(medium) {
			--padding-y: #{rem-calc(22)};
		}

		@include breakpoint-hover {
			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $primary-gradient;
				opacity: 0;
			}

			&:hover,
			&:focus {
				&,
				.be-icon {
					color: $white;
				}

				&::before {
					opacity: 1;
				}
			}
		}
	}

	&__item {
		&:nth-child(odd) {
			background: $table-odd-bg;
		}
		
		&__icon-box {
			flex: 0 0 rem-calc(35);
			width: rem-calc(35);
			font-size: rem-calc(35);
			color: $primary-color;
		}
	}
}
// END .file-list



// --------------------------------------------------------



// 資訊欄位（從綠色標題到Email等資訊）
.office-info {
	margin-bottom: rem-calc(98 - 12); // 12: li margin-bottom

	&__title {
		margin-bottom: em-calc(19 / 26);
		// text-transform: uppercase;
		color: $primary-color;
	}

	&__map-link {
		// display: block;
		margin-left: rem-calc(4);
		padding: rem-calc(2.5) 0;
		color: currentColor;

		@include breakpoint-hover {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid currentColor;
				border-radius: 100%;
				opacity: 0;
				transform: scale(0);
				transition: opacity 0.3s, transform 0.3s;
			}

			&:hover,
			&:focus {
				&::before {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	&__list {
		margin: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: rem-calc(8);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(12);
		}

		a {
			text-decoration: underline;
		}
	}

	&__img {
		margin-bottom: 1rem;
	}
}
// END .office-info



// --------------------------------------------------------



/* Help */

.mt-0 {
	margin-top: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.pt-0 {
	padding-top: 0;
}

.pb-0 {
	padding-bottom: 0;
}

@include breakpoint(medium) {
	.mt-medium-0 {
		margin-top: 0;
	}
	
	.mb-medium-0 {
		margin-bottom: 0;
	}
	
	.pt-medium-0 {
		padding-top: 0;
	}
	
	.pb-medium-0 {
		padding-bottom: 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

// 加速效能用
.translate3d {
	transform: translate3d(0, 0, 0);
}

// 定義 SVG 隱藏於頁面使用
.svg-def {
	position: absolute;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
	speak: never;
}

// 給 Chart 基本寬高
.chart-box {
	width: 100%;
	height: rem-calc(250);

	@include breakpoint(large) {
		height: rem-calc(465);
	}
}

// --------------------------------------------------------

/* Keyframes */
